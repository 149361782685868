import { useTranslation } from "react-i18next";
import { Row, Typography } from "antd";
import { ResponsiveMailRow, BJLogo } from "../components/util/commonComponents";
import { getParameterByName } from "../helpers";
import { MailContainer } from "../components";
import { capitalize } from "lodash";

export const ShareCodePage = () => {
  const { t } = useTranslation();

  const { Title, Text } = Typography;
  const name = capitalize(getParameterByName("name") ?? "").trim();
  const shareCode = getParameterByName("shareCode");

  const renderBody = () => {
    return (
      <>
        <ResponsiveMailRow>
          <Title
            level={4}
            style={{
              fontWeight: "normal",
              textAlign: "center",
              marginBottom: 0,
            }}
          >
            {t("shareCode:title", { name })}
          </Title>
        </ResponsiveMailRow>
        <ResponsiveMailRow>
          <Text
            style={{
              textAlign: "center",
              fontSize: 18,
              whiteSpace: "pre-line",
            }}
          >
            {t("shareCode:description", { name })}
          </Text>
        </ResponsiveMailRow>
        <ResponsiveMailRow>
          <Title
            level={1}
            style={{
              fontWeight: "bold",
              textAlign: "center",
              fontSize: 70,
              marginBottom: 0,
            }}
          >
            {shareCode}
          </Title>
        </ResponsiveMailRow>
        <Row justify={"center"}>
          <Text
            copyable={{
              text: shareCode ?? "",
            }}
            strong
          >
            {t("shareCode:shareCode")}
          </Text>
        </Row>
      </>
    );
  };

  return <MailContainer header={() => <BJLogo />} body={renderBody} />;
};
