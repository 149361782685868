import { firebaseConfig } from "../config";
import firebase from "firebase/app";

import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import "firebase/functions";

firebase.initializeApp(firebaseConfig.firebaseProdConfig);

export { firebase };
