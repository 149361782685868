import { Col, Row, Typography } from "antd";
import { ReactNode } from "react";
import { ReactComponent as LOGO } from "../../assets/logo-purpledark.svg";
import { LogoWrapper } from "../../components/common";

const { Title } = Typography;

const ContentTitle = ({ title }: { title: string }) => (
  <Title style={{ fontWeight: 500 }}>{title}</Title>
);

const HeaderTitle = ({ title }: { title: string }) => (
  <Title style={{ fontWeight: 500 }}>{title}</Title>
);
const Description = ({ description }: { description: string }) => (
  <>
    <Title level={4} style={{ fontWeight: "normal" }}>
      {description}
    </Title>
  </>
);
const ResponsiveMailRow = ({ children }: { children?: ReactNode }) => (
  <Row justify={"center"} style={{ padding: "2rem" }}>
    {children}
  </Row>
);

const ResponsiveMailCol = ({ children }: { children?: ReactNode }) => (
  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
    {children}
  </Col>
);

const ResponsiveFormCol = ({ children }: { children?: ReactNode }) => (
  <Col xs={24} sm={24} md={16} lg={16} xl={8} xxl={8}>
    {children}
  </Col>
);

const ResponsiveTitleDescription = ({
  title,
  description
}: {
  title: string;
  description: string;
}) => {
  return (
    <ResponsiveMailRow>
      <ResponsiveMailCol>
        <div style={{ textAlign: "center" }}>
          <ContentTitle title={title} />
          <Description description={description} />
        </div>
      </ResponsiveMailCol>
    </ResponsiveMailRow>
  );
};

const BJLogo = () => (
  <LogoWrapper>
    <LOGO />
  </LogoWrapper>
);

export {
  ContentTitle as Title,
  Description,
  ResponsiveMailRow,
  ResponsiveMailCol,
  ResponsiveTitleDescription,
  BJLogo,
  ResponsiveFormCol,HeaderTitle
};
