import { useTranslation } from "react-i18next";
import {
  ResponsiveTitleDescription,
  BJLogo,
  MailContainer
} from "../components";

export const HomePage = () => {
  const { t } = useTranslation();
  return (
    <MailContainer
      header={() => <BJLogo />}
      body={() => (
        <ResponsiveTitleDescription
          title={t("home:title")}
          description={t("home:description")}
        />
      )}
    />
  );
};
