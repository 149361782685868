import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Form, Col, Typography } from "antd";
import { getParameterByName } from "../helpers";
import { auth } from "../services";
import {
  BJButton,
  ButtonTypes,
  ResponsiveMailRow,
  ResponsiveTitleDescription,
  BJLogo,
  ResponsiveFormCol,
  MailContainer,
  BJFInput,
  HeaderTitle,
  ResponsiveMailCol,
} from "../components";
import { Password } from "../assets/icons/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

type FormProps = {
  password: string;
  confirmPassword: string;
};

export const ResetPasswordPage = () => {
  const actionCode = getParameterByName("oobCode");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isValidResetUrl, setIsValidResetUrl] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { t } = useTranslation();
  const { Title } = Typography;

  const schema = yup.object().shape({
    password: yup
      .string()
      .required(t("resetPassword:passwordRequired"))
      .min(6, ({ min }) => t("resetPassword:passwordMinRequired", { min })),
    confirmPassword: yup
      .string()
      .required(t("resetPassword:passwordRequired"))
      .min(6, ({ min }) => t("resetPassword:passwordMinRequired", { min }))
      .oneOf([yup.ref("password"), null], t("resetPassword:passwordMismatch")),
  });

  const {
    handleSubmit,
    formState: { errors },
    clearErrors,
    control,
  } = useForm<FormProps>({ resolver: yupResolver(schema) });

  useEffect(() => {
    const validateUrl = async () => {
      if (actionCode) {
        const { status, message } = await auth.verifyPasswordResetCode(
          actionCode
        );
        if (status) {
          setTitle("");
          setIsValidResetUrl(true);
          setDescription(message);
        } else {
          setIsValidResetUrl(false);
          setTitle(t("resetPassword:unVerifiedTitle"));
          setDescription(message);
        }
        setIsLoading(false);
      }
    };

    validateUrl();
  }, [actionCode, t]);

  const onSubmit = async ({ password }: FormProps) => {
    clearErrors();
    setIsSaving(true);
    const { status, message } = await auth.resetPassword(actionCode!, password);
    setIsSaving(false);
    if (status) {
      setIsValidResetUrl(false);
      setTitle(t("resetPassword:passwordResetSuccessTitle"));
      setDescription(message);
    } else {
      setIsValidResetUrl(false);
      setTitle(t("resetPassword:unVerifiedTitle"));
      setDescription(message);
    }
  };

  const ResetPasswordForm = () => {
    return (
      <>
        {isValidResetUrl ? (
          <>
            <ResponsiveMailRow>
              <ResponsiveMailCol>
                <Title
                  level={4}
                  style={{ fontWeight: "normal", textAlign: "center" }}
                >
                  {description}
                </Title>
              </ResponsiveMailCol>
            </ResponsiveMailRow>
            <ResponsiveMailRow>
              <ResponsiveFormCol>
                <Form name="basic" onFinish={handleSubmit(onSubmit)}>
                  <BJFInput
                    control={control}
                    error={!!errors.password}
                    message={errors.password?.message}
                    required={true}
                    fieldName={"password"}
                    placeholder={"Password"}
                    type={"password"}
                    prefix={<Password />}
                    bordered={false}
                    size="large"
                  />
                  <BJFInput
                    control={control}
                    error={!!errors.confirmPassword}
                    help={errors?.confirmPassword?.message}
                    message={errors.confirmPassword?.message}
                    required={true}
                    fieldName={"confirmPassword"}
                    placeholder={"Confirm Password"}
                    type={"password"}
                    prefix={<Password />}
                    bordered={false}
                    size="large"
                  />
                  <BJButton
                    style={{ marginTop: "1rem" }}
                    buttonType={ButtonTypes.Action}
                    size="large"
                    onClick={() => {}}
                    htmlType="submit"
                    disabled={isSaving}
                    loading={isSaving}
                  >
                    {t("resetPassword:button")}
                  </BJButton>
                </Form>
              </ResponsiveFormCol>
            </ResponsiveMailRow>
          </>
        ) : (
          <ResponsiveTitleDescription title={title} description={description} />
        )}
      </>
    );
  };

  return (
    <MailContainer
      isLoading={isLoading}
      header={() => <HeaderTitle title={t("resetPassword:verifiedTitle")} />}
      body={ResetPasswordForm}
      footer={() => (
        <ResponsiveMailRow>
          <Col>
            <BJLogo />
          </Col>
        </ResponsiveMailRow>
      )}
    />
  );
};
