import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getParameterByName } from "../helpers";
import { auth } from "../services";
import {
  MailContainer,
  ResponsiveTitleDescription,
  BJLogo
} from "../components";

export const RecoverEmailPage = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    const resetEmail = async () => {
      const actionCode = getParameterByName("oobCode");
      if (actionCode) {
        const { status, message } = await auth.resetEmail(actionCode);
        if (status) {
          setTitle(t("recoverEmail:verifiedTitle"));
          setDescription(message);
        } else {
          setTitle(t("recoverEmail:unVerifiedTitle"));
          setDescription(message);
        }
        setIsLoading(false);
      }
    };

    resetEmail();
  }, [t]);

  return (
    <MailContainer
      isLoading={isLoading}
      header={() => <BJLogo />}
      body={() => (
        <ResponsiveTitleDescription title={title} description={description} />
      )}
    />
  );
};
