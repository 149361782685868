import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { Form, Radio, Space, Col } from "antd";
import { getParameterByName } from "../helpers";
import { auth } from "../services";
import {
  BJButton,
  ButtonTypes,
  ResponsiveFormCol,
  MailContainer,
  ResponsiveMailRow,
  ResponsiveTitleDescription,
  Title,
  BJLogo,
} from "../components";
import moment from "moment";
import { StyledInput, StyledFormItem } from "../components/common";

interface FormProps {
  reason: string;
  comment: string;
}

export const DeleteConfirmationPage = () => {
  const actionCode = getParameterByName("oobCode");
  const expiry = getParameterByName("exp");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { t } = useTranslation();

  const { handleSubmit, clearErrors, control } = useForm<FormProps>({});

  useEffect(() => {
    const validateUrl = async () => {
      if (!actionCode || !expiry) {
        setDescription(t("common:expiredUrlOrInValidUrl"));
        setIsExpired(true);
        setIsLoading(false);
        return;
      }
      const expireDay = new Date(Number(expiry));
      const today = new Date();

      if (moment(today).isSameOrAfter(moment(expireDay))) {
        setDescription(t("common:expiredUrlOrInValidUrl"));
        setIsExpired(true);
      }
      setIsLoading(false);
    };

    validateUrl();
  }, [expiry, t, actionCode]);

  const onSubmit = async ({ reason, comment }: FormProps) => {
    clearErrors();
    setIsSaving(true);

    const { status, message } = await auth.deleteConfirmation(
      actionCode!,
      reason,
      comment
    );
    setIsSaving(false);
    setIsExpired(false);
    if (status) {
      setIsExpired(true);
      setTitle(t("deleteConfirmation:confirmationSuccessTitle"));
      setDescription(t("deleteConfirmation:confirmationSuccessDescription"));
    } else {
      setIsExpired(true);
      setTitle(t("deleteConfirmation:confirmationFailedTitle"));
      setDescription(message);
    }
  };

  const BodyComponent = () => {
    return (
      <>
        {!isExpired && (
          <ResponsiveMailRow>
            <ResponsiveFormCol>
              <Form name="basic" onFinish={handleSubmit(onSubmit)}>
                <Title title={t("deleteConfirmation:reasonTitle")} />
                <StyledFormItem name="reason">
                  <Controller
                    control={control}
                    name="reason"
                    render={({ field: { onChange, value } }) => (
                      <Radio.Group onChange={t => onChange(t)}>
                        <Space direction="vertical">
                          <Radio value={t("deleteConfirmation:reason1")}>
                            {t("deleteConfirmation:reason1")}
                          </Radio>
                          <Radio value={t("deleteConfirmation:reason2")}>
                            {t("deleteConfirmation:reason2")}
                          </Radio>
                          <Radio value={t("deleteConfirmation:reason3")}>
                            {t("deleteConfirmation:reason3")}
                          </Radio>
                          <Radio value={t("deleteConfirmation:reason4")}>
                            {t("deleteConfirmation:reason4")}
                          </Radio>
                        </Space>
                      </Radio.Group>
                    )}
                  />
                </StyledFormItem>
                <h3>{t("deleteConfirmation:commentTitle")}</h3>
                <Controller
                  control={control}
                  name="comment"
                  render={({ field: { onChange, value } }) => (
                    <StyledInput
                      bordered={true}
                      rows={4}
                      size="large"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <BJButton
                  buttonType={ButtonTypes.Action}
                  size="large"
                  onClick={() => {}}
                  htmlType="submit"
                  disabled={isSaving}
                  loading={isSaving}
                >
                  {t("deleteConfirmation:button")}
                </BJButton>
              </Form>
            </ResponsiveFormCol>
          </ResponsiveMailRow>
        )}
      </>
    );
  };

  return (
    <MailContainer
      isLoading={isLoading}
      header={() => (
        <ResponsiveTitleDescription
          title={!isExpired ? t("deleteConfirmation:title") : title}
          description={
            !isExpired ? t("deleteConfirmation:description") : description
          }
        />
      )}
      body={BodyComponent}
      footer={() => (
        <ResponsiveMailRow>
          <Col>
            <BJLogo />
          </Col>
        </ResponsiveMailRow>
      )}
    />
  );
};
