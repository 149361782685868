import { HeadingContainer, HeadingContainerText } from "./common";
import { WaveComponent } from "./wave";

interface Props {
  render: () => JSX.Element;
}

const Header = ({ render }: Props) => {
  return (
    <HeadingContainer>
      <HeadingContainerText>{render && render()}</HeadingContainerText>
      <WaveComponent color="#ffede1" />
    </HeadingContainer>
  );
};
export { Header };
