import { MainContainer } from "./common";
import { Header, CenteredSpinner } from ".";
const MailContainer = ({
  isLoading,
  header,
  body,
  footer
}: {
  isLoading?: boolean;
  header: () => JSX.Element;
  body: () => JSX.Element;
  footer?: () => JSX.Element;
}) => {
  return (
    <MainContainer>
      <Header render={header} />
      {isLoading ? <CenteredSpinner /> : body && body()}
      {footer && footer()}
    </MainContainer>
  );
};

export { MailContainer };
