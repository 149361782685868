import { firebase } from "../firebase";
import i18n from "i18next";

const verifyEmail = async (
  actionCode: string
): Promise<{ status: boolean; message: string }> =>
  firebase
    .auth()
    .checkActionCode(actionCode)
    .then(response => {
      const email = response.data.email;
      return firebase
        .auth()
        .applyActionCode(actionCode)
        .then(async response => {
          const submitReason = firebase
            .app()
            .functions("europe-west2")
            .httpsCallable("sendEmail");

          await submitReason({
            type: "WELCOME_EMAIL",
            data: {
              email: email,
            },
          });
          return {
            status: true,
            message: i18n.t("verifyEmail:verifiedDescription"),
          };
        });
    })
    .catch(error => {
      switch (error.code) {
        case "auth/expired-action-code":
        case "auth/invalid-action-code":
          return {
            status: false,
            message: i18n.t("common:expiredUrlOrInValidUrl", {
              action: i18n.t("verifyEmail:action"),
            }),
          };
        case "auth/user-disabled":
        case "auth/user-not-found":
          return {
            status: false,
            message: i18n.t("common:userDisabledOrNotFound"),
          };
        default:
          return {
            status: false,
            message: i18n.t("common:somethingWentWrong"),
          };
      }
    });

const verifyPasswordResetCode = async (
  actionCode: string
): Promise<{ status: boolean; message: string }> =>
  firebase
    .auth()
    .verifyPasswordResetCode(actionCode)
    .then(response => {
      return {
        status: true,
        message: i18n.t("resetPassword:verifiedDescription", {
          email: response,
        }),
      };
    })
    .catch(error => {
      switch (error.code) {
        case "auth/expired-action-code":
        case "auth/invalid-action-code":
          return {
            status: false,
            message: i18n.t("common:expiredUrlOrInValidUrl", {
              action: i18n.t("resetPassword:action"),
            }),
          };
        case "auth/user-disabled":
        case "auth/user-not-found":
          return {
            status: false,
            message: i18n.t("common:userDisabledOrNotFound"),
          };
        default:
          return {
            status: false,
            message: i18n.t("common:somethingWentWrong"),
          };
      }
    });

const resetPassword = async (
  actionCode: string,
  newPassword: string
): Promise<{ status: boolean; message: string }> =>
  firebase
    .auth()
    .confirmPasswordReset(actionCode, newPassword)
    .then(response => {
      return {
        status: true,
        message: i18n.t("resetPassword:passwordResetSuccessDescription"),
      };
    })
    .catch(error => {
      console.log("error ----------- ", JSON.stringify(error));
      switch (error.code) {
        case "auth/weak-password":
          return {
            status: false,
            message: i18n.t("common:weakPassword"),
          };
        case "auth/expired-action-code":
        case "auth/invalid-action-code":
          return {
            status: false,
            message: i18n.t("common:expiredUrlOrInValidUrl", {
              action: i18n.t("resetPassword:action"),
            }),
          };
        case "auth/user-disabled":
        case "auth/user-not-found":
          return {
            status: false,
            message: i18n.t("common:userDisabledOrNotFound"),
          };
        default:
          return {
            status: false,
            message: i18n.t("common:somethingWentWrong"),
          };
      }
    });

const deleteConfirmation = async (
  actionCode: string,
  reason: string,
  comment: string
) => {
  try {
    const submitReason = firebase
      .app()
      .functions("europe-west2")
      .httpsCallable("onUserDeletionConfirmation");

    const { data: response } = await submitReason({
      actionCode,
      reason,
      comment,
    });
    return response;
  } catch (error) {
    return {
      status: false,
      message: i18n.t("common:somethingWentWrong"),
    };
  }
};

const resetEmail = async (
  actionCode: string
): Promise<{ status: boolean; message: string }> =>
  firebase
    .auth()
    .checkActionCode(actionCode)
    .then(response => {
      const email = response.data.email;
      return firebase
        .auth()
        .applyActionCode(actionCode)
        .then(async response => {
          return {
            status: true,
            message: i18n.t("recoverEmail:verifiedDescription", {
              email: email,
            }),
          };
        });
    })
    .catch(error => {
      switch (error.code) {
        case "auth/expired-action-code":
        case "auth/invalid-action-code":
          return {
            status: false,
            message: i18n.t("common:expiredUrlOrInValidUrl", {
              action: i18n.t("verifyEmail:action"),
            }),
          };
        case "auth/user-disabled":
        case "auth/user-not-found":
          return {
            status: false,
            message: i18n.t("common:userDisabledOrNotFound"),
          };
        default:
          return {
            status: false,
            message: i18n.t("common:somethingWentWrong"),
          };
      }
    });

export const auth = {
  verifyEmail,
  verifyPasswordResetCode,
  resetPassword,
  deleteConfirmation,
  resetEmail,
};
