import {
  VerifyEmailPage,
  ResetPasswordPage,
  DeleteConfirmationPage,
  RecoverEmailPage,
  HomePage,
  ShareCodePage,
} from "./pages";
import { getParameterByName } from "./helpers";
import { ThemeProvider } from "styled-components";
import { theme } from "./components/util/theme";
import { GlobalStyle } from "./components/util/GlobalStyle";
import "./styles/App.scss";
import i18next from "i18next";

export const App = () => {
  const mode = getParameterByName("mode");
  const lang = getParameterByName("lang");
  i18next.changeLanguage(lang ?? "sv");

  const getPage = () => {
    switch (mode) {
      case "verifyEmail":
        return <VerifyEmailPage />;
      case "resetPassword":
        return <ResetPasswordPage />;
      case "deleteConfirmation":
        return <DeleteConfirmationPage />;
      case "recoverEmail":
        return <RecoverEmailPage />;
      default:
        return getParameterByName("shareCode") ? (
          <ShareCodePage />
        ) : (
          <HomePage />
        );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {getPage()}
    </ThemeProvider>
  );
};
