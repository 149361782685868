import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  white: "#fff",
  black: "#000000",
  button: {
    primary: "#4D3D71",
    secondary: "rgba(231, 230, 247, 0.5)",
    success: "#60D171",
    danger: "#E5989B",
    paging: "#f0f1f2",
  },
  primary: "#FFEDE1",
  secondary: "#f7f7f7",
  text: {
    primary: "#4D3D71",
    danger: "#E5989B",
  },
  hover: {
    menu: "#ffe5d3",
  },
  input: {
    background: "#e2dede1a",
  },
  active: {
    background: "#ffe5d3",
  },
  icon: {
    gray: "#AAA8A8",
  },
};
