import { Button } from "antd";
import { NativeButtonProps } from "antd/lib/button/button";
import React, { FC } from "react";
import styled, { css } from "styled-components";

export const BJButton: FC<BJButtonProps> = ({
  children,
  icon,
  buttonType,
  onClick,
  rounded,
  ...btnProps
}) => {
  return (
    <StyledButton
      onClick={onClick}
      buttonType={buttonType}
      rounded={rounded ? rounded : false}
      {...btnProps}
      icon={icon && <IconWrapper>{icon}</IconWrapper>}
    >
      {children}
    </StyledButton>
  );
};

export enum ButtonTypes {
  Add = "add",
  Save = "save",
  Secondary = "secondary",
  Delete = "delete",
  Primary = "primary",
  Action = "action"
}
interface BJButtonProps extends NativeButtonProps {
  icon?: JSX.Element;
  buttonType?: ButtonTypes;
  onClick: () => any;
  rounded?: boolean;
}

const StyledButton = styled(
  ({ rounded, buttonType, ...props }: BJButtonProps) => <Button {...props} />
)`
  background-color: ${(props) => props.theme.button.primary};
  color: ${(props) => props.theme.white};
  border-radius: ${(props) => (props.rounded ? "10rem" : "15px")};
  border: none;
  box-shadow: none;
  padding-left: 1.8rem !important;
  padding-right: 1.8rem !important;
  &:hover {
    background-color: ${(props) => props.theme.primary};
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  display: flex;
  justify-content: center;
  align-items: center;

  &.ant-btn-loading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.primary};
  }

  &.ant-btn > .ant-btn-loading-icon {
    display: flex;
  }

  &.ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    background: ${(props) => props.theme.button.primary};
    color: white;
  }

  ${(props) => {
    switch (props.buttonType) {
      case "add": {
        return css`
          background-color: ${(props) => props.theme.primary};
          color: ${(props) => props.theme.text.primary};
        `;
      }
      case "save": {
        return css`
          background-color: ${(props) => props.theme.button.success};
          color: ${(props) => props.theme.white};
        `;
      }
      case "secondary": {
        return css`
          background-color: ${(props) => props.theme.button.secondary};
          color: ${(props) => props.theme.text.primary};
        `;
      }
      case "delete": {
        return css`
          background-color: ${(props) => props.theme.white};
          color: ${(props) => props.theme.text.danger};
          border: 1px solid ${(props) => props.theme.button.danger};
        `;
      }
      case "action": {
        return css`
          background-color: ${(props) => props.theme.button.primary};
          color: ${(props) => props.theme.primary};
          border-radius: 5px;
          margin: 0 auto;
          width: 50%;
          @media screen and (max-width: 600px) {
            width: 100%;
          }
        `;
      }
      default: {
        return css`
          background-color: ${(props) => props.theme.button.primary};
          color: ${(props) => props.theme.white};
        `;
      }
    }
  }}
`;

const IconWrapper = styled.div`
  padding-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
