const firebaseDevConfig = {
  apiKey: "AIzaSyA8RxHyvVOTaitGWv4TdQofQmTxs7y20uA",
  authDomain: "baby-journey-dev-9894e.firebaseapp.com",
  databaseURL: "https://baby-journey-dev-9894e.firebaseio.com",
  projectId: "baby-journey-dev-9894e",
  storageBucket: "baby-journey-dev-9894e.appspot.com",
  messagingSenderId: "1004280397684",
  appId: "1:1004280397684:web:6241d3c699d5e1b8ae3621",
  measurementId: "G-0TJEDGEKZC",
};

const firebaseStagingConfig = {
  apiKey: "AIzaSyA3izRV-_a-_QxRNSo6VRWiJ4Gmu-hyuKg",
  authDomain: "baby-journey-2fa3d.firebaseapp.com",
  databaseURL: "https://baby-journey-2fa3d.firebaseio.com",
  projectId: "baby-journey-2fa3d",
  storageBucket: "baby-journey-2fa3d.appspot.com",
  messagingSenderId: "919922992504",
  appId: "1:919922992504:web:984618e7b402111966d48d",
  measurementId: "G-H7W8J6TH87",
};

const firebaseProdConfig = {
  apiKey: "AIzaSyDsrhCRA6d8kKd5jN_PngBwDsviEhj0e2s",
  authDomain: "baby-journey-7cd30.firebaseapp.com",
  databaseURL: "https://baby-journey-7cd30.firebaseio.com",
  projectId: "baby-journey-7cd30",
  storageBucket: "baby-journey-7cd30.appspot.com",
  messagingSenderId: "37283275410",
  appId: "1:37283275410:web:cd58b0be878496d014bc4b",
  measurementId: "G-7F4TJZMSKS",
};

export const firebaseConfig = {
  firebaseDevConfig,
  firebaseStagingConfig,
  firebaseProdConfig,
};
