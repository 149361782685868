interface Props {
  width?: number;
  height?: number;
  color?: string;
}

export function WaveComponent({
  width,
  height,
  color = "blue",
  ...rest
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 414 30"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M414 5.833c-16.97-1.483-43.353-3.586-65.438-3.586-53.969 0-118.695 11.665-187.509 12.348C92.239 15.278 37.03 9.727 0 8.645V0h414v5.833Z"
        fill={color}
      />
    </svg>
  );
}

export default WaveComponent;
