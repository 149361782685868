import { Form, FormItemProps, Input, InputProps, Typography } from "antd";
import styled from "styled-components";

import {
  Control,
  Controller,
  ControllerRenderProps,
  Path
} from "react-hook-form";

export const BJFInput = <T,>({
  error,
  message,
  label,
  required,
  control,
  fieldName,
  autoFocus,
  extra,
  ...rest
}: {
  error?: boolean;
  message?: string;
  label?: string;
  required?: boolean;
  control: Control<T>;
  fieldName: Path<T>;
  extra?: string;
} & FormItemProps<T> &
  InputProps) => {
  return (
    <Form.Item
      label={label}
      required={required}
      validateStatus={error ? "error" : undefined}
      {...(message
        ? {
            help: (
              <Typography.Paragraph type="danger">
                {message}
              </Typography.Paragraph>
            )
          }
        : {})}
      {...(extra
        ? {
            extra: (
              <Typography.Paragraph type="warning">
                {extra}
              </Typography.Paragraph>
            ) as any
          }
        : undefined)}
    >
      <Controller<T>
        control={control}
        name={fieldName}
        render={({ field }: { field: ControllerRenderProps<T, Path<T>> }) => (
          <StyledPassword
            {...field}
            {...rest}
            value={field.value as string | number | readonly string[]}
          />
        )}
      />
    </Form.Item>
  );
};

const StyledPassword = styled(Input.Password)`
  margin-top: 1rem;
  background-color: ${(props) => props.theme.secondary} !important;
  padding: 1rem;
  .ant-input-affix-wrapper > input,
  .ant-input {
    background-color: ${(props) => props.theme.secondary} !important;
    padding-left: 1rem !important;
  }
`;
