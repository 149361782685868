import styled from "styled-components";
import { Form, Input, Row } from "antd";

const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  @media (max-width: 400px) {
    height: 110vh;
  }
`;

const StyledLoginContainer = styled.div`
  width: 40%;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    width: 60%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeadingContainerText = styled.div`
  position: relative;
  text-align: center;
  padding: 2rem 2rem 0.4rem;
  background-color: #ffede1;
  @media screen and (max-width: 600px) {
    padding: 2rem 2rem 1rem;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

const ParagraphContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 3rem;
  text-align: center;
  @media screen and (max-width: 600px) {
    padding: 2rem;
  }
`;

const StyledRow = styled(Row)`
  padding: 0.5rem 1rem 1.5rem;
  flex-direction: column;
  @media (max-width: 600px) {
    padding: 1.2rem;
  }
`;

const StyledInput = styled(Input.TextArea)`
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  background-color: ${(props) => props.theme.white} !important;
  padding: 1rem 0.8rem;
  .ant-input-affix-wrapper > input,
  .ant-input {
    background-color: ${(props) => props.theme.secondary} !important;
    padding-left: 1rem !important;
  }
`;

const StyledPassword = styled(Input.Password)`
  margin-top: 1rem;
  background-color: ${(props) => props.theme.secondary} !important;
  padding: 1rem;
  .ant-input-affix-wrapper > input,
  .ant-input {
    background-color: ${(props) => props.theme.secondary} !important;
    padding-left: 1rem !important;
  }
`;

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-explain,
  .ant-form-item-explain-error {
    padding-left: 1rem;
  }
`;

export {
  StyledLoginContainer,
  MainContainer,
  ParagraphContainer,
  StyledRow,
  StyledInput,
  StyledFormItem,
  HeadingContainer,
  HeadingContainerText,
  LogoWrapper,
  StyledPassword
};
